import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        jobTransfers: [],
        paginateLinks: {},
        jobTransfer: {},
    },

    getters: {
        jobTransfers: (state) => {
            return state.jobTransfers;
        },

        paginateLinks: (state) => {
            return state.paginateLinks;
        },

        jobTransfer: (state) => {
            return state.jobTransfer;
        },
    },
    mutations: {
        SET_JOB_TRANSFERS(state, jobTransfers) {
            state.jobTransfers = jobTransfers;
        },

        SET_PAGINATE_LINKS(state, paginateLinks) {
            state.paginateLinks = paginateLinks;
        },

        SET_JOB_TRANSFER(state, jobTransfer) {
            state.jobTransfer = jobTransfer;
        },

        CREATE_JOB_TRANSFER_ON_LIST(state, jobTransfer) {
            state.jobTransfers.unshift(jobTransfer);
        },

        CREATE_JOB_TRANSFER(state, jobTransfer) {
            state.jobTransfer = jobTransfer;
        },

        UPDATE_JOB_TRANSFER_ON_LIST(state, jobTransfer) {
            let index = state.jobTransfers.findIndex(item => item.id === jobTransfer.id);
            state.jobTransfers.splice(index, 1, jobTransfer);
        },

        UPDATE_JOB_TRANSFER(state, jobTransfer) {
            state.jobTransfer = jobTransfer;
        },

        DELETE_JOB_TRANSFER_ON_LIST(state, orderCreatorId) {
            let index = state.jobTransfers.findIndex(item => item.id === orderCreatorId);
            state.jobTransfers.splice(index, 1);
        },

        DELETE_JOB_TRANSFER(state) {
            state.jobTransfer = {};
        },

        RESET_JOB_TRANSFERS(state) {
            state.jobTransfers = [];
        },

        RESET_JOB_TRANSFER(state) {
            state.jobTransfer = {};
        },
    },
    actions: {
        commitSetJobTransfers: async ({commit}, responseData) => {
            await commit('SET_JOB_TRANSFERS', responseData.data);
        },

        commitSetPaginateLinks: async ({commit}, responseData) => {
            await commit('SET_PAGINATE_LINKS', responseData.meta);
        },

        commitSetJobTransfer: async ({commit}, responseData) => {
            await commit('SET_JOB_TRANSFER', responseData.data)
        },

        commitCreateJobTransferOnList: async ({commit}, responseData) => {
            await commit('CREATE_JOB_TRANSFER_ON_LIST', responseData.data);
        },

        commitCreateJobTransfer: async ({commit}, responseData) => {
            await commit('CREATE_JOB_TRANSFER', responseData.data);
        },

        commitUpdateJobTransferOnList: async ({commit}, responseData) => {
            await commit('UPDATE_JOB_TRANSFER_ON_LIST', responseData.data);
        },

        commitUpdateJobTransfer: async ({commit}, responseData) => {
            await commit('UPDATE_JOB_TRANSFER', responseData.data);
        },

        commitDeleteJobTransferOnList: async ({commit}, orderCreatorId) => {
            await commit('DELETE_JOB_TRANSFER_ON_LIST', orderCreatorId);
        },

        commitDeleteJobTransfer: async ({commit}) => {
            await commit('DELETE_JOB_TRANSFER');
        },

        commitResetJobTransfers: async ({commit}) => {
            await commit('RESET_JOB_TRANSFERS');
        },

        commitResetJobTransfer: async ({commit}) => {
            await commit('RESET_JOB_TRANSFER');
        },

        // get list of jobTransfer by api call.
        getJobTransfers: async ({dispatch}, params = {}) => {
            const path = `v1/job-transfers`;

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetJobTransfers', response.data);
                dispatch('commitSetPaginateLinks', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // get single jobTransfer by api call.
        getJobTransfer: async ({dispatch}, paramObj) => {
            const path = `v1/job-transfers/${paramObj.id}`;
            const params = paramObj.params ?? {};

            return axios.get(path, {params: params}).then(response => {
                dispatch('commitSetJobTransfer', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch(error => {
                return responseErrorHandler(error);
            });
        },

        // create new jobTransfer on list by api call.
        postJobTransferOnList: async ({dispatch}, data) => {
            const path = `v1/job-transfers`;

            return axios.post(path, data).then((response) => {
                dispatch('commitCreateJobTransferOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // create new jobTransfer by api call.
        postJobTransfer: async ({dispatch}, data) => {
            const path = `v1/job-transfers`;

            return axios.post(path, data).then((response) => {

                dispatch('commitCreateJobTransfer', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing jobTransfer on list by api call.
        putJobTransferOnList: async ({dispatch}, dataObj) => {
            const path = `v1/job-transfers/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateJobTransferOnList', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing jobTransfer by api call.
        putJobTransfer: async ({dispatch}, dataObj) => {
            const path = `v1/job-transfers/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) => {
                dispatch('commitUpdateJobTransfer', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular jobTransfer on list by api call.
        deleteJobTransferOnList: async ({dispatch}, id) => {
            const path = `v1/job-transfers/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteJobTransferOnList', id);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // delete a particular jobTransfer by api call.
        deleteJobTransfer: async ({dispatch}, id) => {
            const path = `v1/job-transfers/${id}`;

            return axios.delete(path).then((response) => {
                dispatch('commitDeleteJobTransfer', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // reset jobTransfers state.
        resetJobTransfers: async ({dispatch}) => {
            dispatch('commitResetJobTransfers');
        },

        // reset jobTransfer state.
        resetJobTransfer: async ({dispatch}) => {
            dispatch('commitResetJobTransfer')
        },

        // create new jobTransfer request by api call.
        postJobTransferRequest: async ({dispatch}, data) => {
            const path = `v1/job-transfers/send-request`;

            return axios.post(path, data).then((response) => {

                dispatch('commitCreateJobTransfer', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing jobTransfer by api call.
        postJobTransferAccept: async ({dispatch}, dataObj) => {
            const path = `v1/job-transfers/${dataObj.id}/accept-request`;
            const data = dataObj.data;

            return axios.post(path, data).then((response) => {
                dispatch('commitUpdateJobTransfer', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

        // update single existing jobTransfer by api call.
        postJobTransferDecline: async ({dispatch}, dataObj) => {
            const path = `v1/job-transfers/${dataObj.id}/decline-request`;
            const data = dataObj.data;

            return axios.post(path, data).then((response) => {
                dispatch('commitUpdateJobTransfer', response.data);

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) => {
                return responseErrorHandler(error);
            });
        },

    },
};
